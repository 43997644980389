import Carousel from 'stimulus-carousel'
export default class extends Carousel {
  static values = { autoplay: Boolean }
  connect() {
    super.connect()

    this.swiper

    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    if (this.autoplayValue) { // 执行自动播放
      return {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false, //就算触摸了也继续滚动
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable:true
        }
      }
    } else { // 不自动播放
      return {
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable:true,
        },
      }
    }
  }

}

import {  Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // connect() {
  //   this.element.textContent = "Hello World!"
  // }
  append(event) {
    event.preventDefault();
    let input = document.createElement('input')
    input.className = 'form__input'
    this.element.appendChild(input)
  }
}

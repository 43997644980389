import 'alpine-turbo-drive-adapter';
import Alpine from "alpinejs";
import mask from '@alpinejs/mask'
Alpine.plugin(mask)

import CountDown from "./countdown";
Alpine.plugin(CountDown);

import SendMobilePhoneCode from './send_mobile_phone_code';
Alpine.plugin(SendMobilePhoneCode);

import TurboModal from '../../components/turbo_modal_component/turbo_modal_component'
Alpine.plugin(TurboModal);

window.Alpine = Alpine;
Alpine.start();

import { Controller } from '@hotwired/stimulus'
import Atropos from 'atropos';


export default class extends Controller {
  connect () {
    const myAtropos = Atropos({
      el: this.element,
      // rest of parameters
    })
  }
}

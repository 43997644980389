import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'location']
  connect() {
    console.log(111)
  }
  handle(e) {
    const svg = e.target.parentNode.querySelector('svg').cloneNode(true)
    const id = e.target.dataset.id
    this.locationTarget.innerHTML = ''
    this.locationTarget.appendChild(svg)
    this.inputTarget.value = id
  }
}

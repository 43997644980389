export default function (Alpine) {
  Alpine.data("countdown", () => ({
    counter: 0,
    start() {
      this.run()
    },
    run() {
      if(this.counter == 0) {
        this.$dispatch('countdown:completed', this)
        return;
      }
      this.counter--
      setTimeout(this.run.bind(this), 1000)
    }
  }))
}

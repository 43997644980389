import { Controller } from "@hotwired/stimulus";
import tippy from 'tippy.js';
// import 'tippy.js/dist/tippy.css';
// import 'tippy.js/themes/light-border.css';

export default class extends Controller {
  static values = { position: String,title:String }

  connect() {
    this.titleValue = this.element.getAttribute('title');
    this.element.removeAttribute('title');
    this.tippy = tippy(this.element, {
      content: this.titleValue,
      theme: 'light-border',
      placement: this.positionValue || 'top'
    })
  }

  disconnect() {
    this.element.title = this.titleValue;
    if(this.tippy) this.tippy.destroy()
  }
}

// 此controller实现了当点击【删除】按钮时，异步提示【是否确定要删除？】信息，用户再次确认则执行删除工作，否则3000毫秒后自动恢复默认。
// 好处是避免了alert弹窗，详细教程见：  https://onrails.blog/2019/02/27/stimulus-js-tutorial-interactive-deletes-with-rails-ujs/
//
// 将需要添加删除按钮的地方修改为以下代码：
// <span class="sm:ml-3" data-controller="delete">
//   <%= button_to post_path(post), method: :delete, data: {target: 'delete.link', action: 'click->delete#click' }, type: "button", class: "inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" do %>
//     删除
//   <% end %>
// </span>

const RESET_TIMEOUT_MILLIS = 3000;
const CONFIRMATION_MESSAGE = '<strong> 是否确定要删除? </strong>';

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['link']
  
  connect() {
    this.delete = false;
  }

  click(event) {

    if (this.delete) {
      this.element.style = 'display: none;';
      this.linkTarget.addEventListener('ajax:success', this.handleSuccess.bind(this))
      this.linkTarget.addEventListener('ajax:error', this.handleError.bind(this))
    } else {
      this.oldMessage = this.linkTarget.innerHTML;
      this.linkTarget.innerHTML = CONFIRMATION_MESSAGE;
      this.delete = true;
      this.timeout = setTimeout(() => {
        this.resetState();
      }, RESET_TIMEOUT_MILLIS);
      event.preventDefault();
      return false;
    }
  }

  handleSuccess(event) {
    clearTimeout(this.timeout);
    this.element.parentNode.removeChild(this.element);
  }

  handleError(event) {
    clearTimeout(this.timeout);
    this.resetState();
    this.element.style = '';
  }

  resetState() {
    if (this.delete) {
      this.linkTarget.removeEventListener('ajax:success', this.handleSuccess.bind(this))
      this.linkTarget.removeEventListener('ajax:error', this.handleError.bind(this))
      this.linkTarget.innerHTML = this.oldMessage;
      this.delete = false;
    }
  }
}
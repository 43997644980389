export default (Alpine) => {
  Alpine.data('turbo_modal', ()=>({
    open: false,
    init() {
      this.$nextTick(()=> this.open = true)
    },
    get _position(){
      return this.position || 'center'
    },
    get xTransition(){
      switch (this._position) {
        case 'left':
          return({
            'x-transition:enter': "transition-all ease-in duration-150",
            'x-transition:enter-start': "-left-80 opacity-50",
            'x-transition:enter-end': "left-0 opacity-100",
            'x-transition:leave': "transition-all ease-out duration-150",
            'x-transition:leave-start': "left-0 opacity-100",
            'x-transition:leave-end': "-left-80 opacity-50"
          })
        case 'right':
          return({
            'x-transition:enter': "transition-all ease-in-out duration-300",
            'x-transition:enter-start': "-right-80 opacity-50",
            'x-transition:enter-end': "right-0 opacity-100",
            'x-transition:leave': "transition-all ease-in-out duration-300",
            'x-transition:leave-start': "right-0 opacity-100",
            'x-transition:leave-end': "-right-80 opacity-50",
          })
        default:
          return({
            'x-transition:enter': "transition ease-in-out duration-150",
            'x-transition:enter-start': "scale-75 opacity-50",
            'x-transition:enter-end': "scale-105 opacity-100",
            'x-transition:leave': "transition ease-in duration-150",
            'x-transition:leave-start': "opacity-100",
            'x-transition:leave-end': "scale-75 opacity-50"
          })
      }
    },
    get _containerClass(){
      var cssName = this.containerClass || ''
      switch (this._position) {
        case 'right': return `fixed right-0 top-0 bottom-0 ${cssName}`
        case 'left': return `fixed left-0 top-0 bottom-0 ${cssName}`
        default: return  `${cssName}`
      }
    },
    get _modalClass(){
      var cssName = this.containerClass || ''
      switch (this._position) {
        case 'right':
        case 'left':
          return `relative w-full h-full bg-white shadow ${cssName}`
        default:
          return `relative w-full h-full bg-white shadow rounded-lg ${cssName}`
      }
    },
    get containerBind() {
      return {
      ['x-show']: 'open',
      ['@click.outside']: "open=false",
      ['class']: this._containerClass,
      ...this.xTransition
      }
    },
    get modalBind(){
      return {
        ['class']: this._modalClass,
      }
    }
  }))
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }
  
  execute() {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", this.urlValue);
    xhr.responseType = "blob";
    xhr.send();
    
    xhr.onload = function() {
      if (this.status === 200 || this.status === 304) {
        const url = URL.createObjectURL(this.response);
        const el = document.createElement("a");

        el.style.display = "none";
        el.href = url;
        el.target = '_blank'
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
        URL.revokeObjectURL(url);
      }
    };
  }
}

import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

function validPhoneNumber(phone_number){
  if(!phone_number) return false
  return phone_number.match(/^1\d{10}$/)
}

function countDown(button, input, originButtonText, second){
  if(second){
    button.textContent = `${second}秒后可重发`
    setTimeout(countDown, 1000, button, input, originButtonText, second - 1);
  }else{
    button.textContent = originButtonText
    button.removeAttribute('disabled')
    input.removeAttribute('readonly')
  }
}

// function countDown(){
//   if(this.second){
//     this.element.textContent = `${this.second}秒后可重发`
//     this.second--
//   }else{
//     this.element.textContent = this.buttonText
//     clearInterval(this.interval)
//   }
// }

export default class extends Controller {
  static targets = [ "mobilePhoneInput", "sendButton", "message" ]
  static values = { url: String, second: Number }
  connect () {

  }

  send() {
    if(this.sendButtonTarget.getAttribute('disabled')) return

    const phone_number = this.mobilePhoneInputTarget.value
    if(!validPhoneNumber(phone_number)){
      this.messageTarget.textContent = '请输入正确的手机号!'
      return
    }

    this.messageTarget.textContent = ''
    this.sendButtonTarget.setAttribute('disabled', true)
    const req = new FetchRequest('post', this.urlValue, { body: JSON.stringify({ mobile_phone: phone_number }) })
    req.perform().then((resp) => {
      if(resp.ok){
        resp.json.then(({ error, message }) => {
          if(error){
            this.sendButtonTarget.removeAttribute('disabled')
            this.mobilePhoneInputTarget.removeAttribute('readonly')
          }else{
            this.mobilePhoneInputTarget.setAttribute('readonly', true)
            countDown(this.sendButtonTarget, this.mobilePhoneInputTarget, this.sendButtonTarget.textContent, this.secondValue)
          }
          this.messageTarget.textContent = message
        }).catch(_ => {
          this.messageTarget.textContent = '发送失败!!!'
        })
        // const context = {
        //   buttonText: this.sendButtonTarget.textContent,
        //   element: this.sendButtonTarget,
        //   second: 5
        // }
        // context.interval = setInterval(countDown.bind(context), 1000);
      }else{
        this.messageTarget.textContent = '发送失败，请稍后再试。'
        this.sendButtonTarget.removeAttribute('disabled')
      }
    }).catch(_resp => {
      this.messageTarget.textContent = '发送失败，可能网络出现了问题，请稍后再试。'
      this.sendButtonTarget.removeAttribute('disabled')
    })
  }
}

export class BlobUpload {
  constructor(blob) {
    this.blob = blob
    this.file = blob.file
    this.dataBuilder = blob.dataBuilder || ((ctx) => ctx.file.slice())
    const { url, headers } = blob.directUploadData

    this.xhr = new XMLHttpRequest
    this.xhr.open("POST", url, true)
    // this.xhr.responseType = "text"
    // for (const key in headers) {
    //   this.xhr.setRequestHeader(key, headers[key])
    // }
    this.xhr.addEventListener("load", event => this.requestDidLoad(event))
    this.xhr.addEventListener("error", event => this.requestDidError(event))
  }

  create(callback) {
    // debugger
    this.callback = callback
    var formData
    formData = new FormData()
    formData.append('key', this.blob.attributes.key)
    formData.append('token', this.blob.directUploadData.headers['x-token'])
    formData.append('file', this.file)
    // this.xhr.send(this.dataBuilder(this.blob))
    this.xhr.send(formData)
  }

  requestDidLoad(event) {
    const { status, response } = this.xhr
    if (status >= 200 && status < 300) {
      this.callback(null, response)
    } else {
      this.requestDidError(event)
    }
  }

  requestDidError(event) {
    this.callback(`Error storing "${this.file.name}". Status: ${this.xhr.status}`)
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="download-file"
export default class extends Controller {
  // 下载后的文件文件名(带后缀)
  static values = { url: String, name: String }
  
  execute() {
    const that = this;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", this.urlValue);
    xhr.responseType = "blob";
    xhr.send();

    xhr.onload = function() {
      if (this.status === 200 || this.status === 304) {
        const url = URL.createObjectURL(this.response);
        const el = document.createElement("a");
        el.style.display = "none";
        el.href = url;
        el.download = that.nameValue;
        document.body.appendChild(el);
        el.click();
        document.body.removeChild(el);
        URL.revokeObjectURL(url);
      }
    };
  }
}

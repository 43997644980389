// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "./controllers"
import "./external_controllers"
import "trix"
// import "@rails/actiontext"
// @rails/actiontext 不支持七牛云上传，所以对代码进行了改造
// 目录 ../../vendor/actiontext 和 ../../vendor/activestorage 里的代码别动
import "../../vendor/javascript/actiontext"
import * as ActiveStorage from "../../vendor/javascript/activestorage"
ActiveStorage.start()
import "./alpinejs"
import "./rails_ujs"